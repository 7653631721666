export const msalConfig = {
  auth: {
    clientId: process.env.MSAL_CLIENTID,
    authority: `https://${process.env.MSAL_TENANT}.b2clogin.com/${process.env.MSAL_TENANT}.onmicrosoft.com/B2C_1_EmailSignIn`,
    knownAuthorities: [`${process.env.MSAL_TENANT}.b2clogin.com`],
    redirectUri: process.env.MSAL_REDIRECT_URI,
    postLogoutRedirectUri: process.env.MSAL_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
