import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import getConfig from "next/config";
import { setCookie, getCookie } from "@/common/utility/cookie";

const UserInfoContext = React.createContext<any | null>(null);
const userInfoKey = "userInfo";

export function useUserInfo() {
  return useContext(UserInfoContext);
}

export function UserInfoProvider({ children }) {
  const { publicRuntimeConfig } = getConfig();
  const [dataCache, setDataCache] = useState({});

  const getUserInfo = async () => {
    try {
      const response = await fetch(`/api/profile/getCurrentUser`);
      if (response.ok) {
        const userInfo = await response.json();
        return userInfo;
      }
      return {};
    } catch (error) {
      console.error("Error fetching user info:", error);
      return {};
    }
  };

  const fetchUserInfo = async () => {
    if (!dataCache[userInfoKey]) {
      const userInfo = await getUserInfo();
      const organisations = userInfo?.organisations;
      if (organisations?.length > 0) {
        localStorage.setItem("organisationId", organisations[0].organisationId);
      }
      setDataCache((prevCache) => ({
        ...prevCache,
        [userInfoKey]: Object.freeze(userInfo),
      }));
    }
  };

  const updateUserInfo = async () => {
    setDataCache((prevCache) => {
      const updatedCache = { ...prevCache };
      delete updatedCache[userInfoKey];
      return updatedCache;
    });

    await fetchUserInfo();
  };

  const userInfo = () => {
    return dataCache[userInfoKey] || {};
  };

  const userInfoContextValue = useMemo(
    () => ({
      dataCache,
      fetchUserInfo,
      updateUserInfo,
      userInfo,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataCache, fetchUserInfo, updateUserInfo]
  );

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once when the component mounts

  return (
    <UserInfoContext.Provider value={userInfoContextValue}>
      {children}
    </UserInfoContext.Provider>
  );
}
