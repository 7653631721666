"use client";

import { useState, useEffect } from "react";
import type { AppProps } from "next/app";
import Router from "next/router";
import { ThemeProvider } from "styled-components";
import { ProSidebarProvider } from "react-pro-sidebar";

import {
  ChakraProvider,
  extendTheme,
  Flex,
  Spinner,
  Text,
  ColorModeScript,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import GlobalStyle from "@/components/globalstyles";
import { dark } from "@/common/theme";
import { LanguageProvider } from "@/components/userprofile/language/lang-context";
import { OrganisationSettingsProvider } from "@/common/context/organisation-settings-context";
import { UserPermissionsProvider } from "@/common/context/user-permissions-context";
import { UserInfoProvider } from "@/common/context/user-info-context";
import ThemeWrapper from "@/common/wrapper/themeWrapper";
import { DashboardProvider } from "@/components/dashboard/dashboard-context";
import Head from "next/head";
import firebaseCloudMessaging from "../firebase";
import AlertOnScreen from "@/components/firebase/AlertOnScreen";
import getConfig from "next/config";
import { IFireBaseConfiguration } from "@/interfaces/IFirebaseConfiguration";
import { isSupported } from "firebase/messaging";
import { setCookie, getCookie } from "@/common/utility/cookie";
import { trackLogin, getFavouriteDashboard } from "@/services/useAccount";
import { useRouter } from "next/router";

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "../msalConfig";
import {
  BrowserAuthError,
  PublicClientApplication,
  InteractionRequiredAuthError,
  EventType,
} from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

export default function App({
  Component,
  pageProps: { ...pageProps },
}: AppProps) {
  const [mounted, setMounted] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [interactionInProgress, setInteractionInProgress] =
    useState<boolean>(false);
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fcmToken, setFcmToken] = useState<string | undefined>(undefined);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  /* START - MSAL SETUP */
  async function initialize() {
    await msalInstance.initialize();
    setInitialized(true);
    setMounted(true);
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_START ||
        event.eventType === EventType.LOGOUT_START
      ) {
        setInteractionInProgress(true);
      } else if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.LOGOUT_SUCCESS
      ) {
        setInteractionInProgress(false);
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    if (initialized && typeof window !== "undefined") {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        msalInstance.loginRedirect().catch((error) => {
          if (
            error instanceof BrowserAuthError &&
            error.errorCode === "interaction_in_progress"
          ) {
            router.reload();
          }
        });
      }
    }
  }, [initialized]);

  const fetchAndSetToken = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const tokenResponse = await msalInstance.acquireTokenSilent({
          account: accounts[0],
          scopes: ["openid"], // Specify the required scopes here
        });
        // Set the token in a cookie
        setCookie("token", tokenResponse.idToken, tokenResponse.expiresOn);
        setIsAuthenticated(true);

        if (!localStorage.getItem("favoriteDashboard")) {
          await trackLogin(false).catch((x) =>
            console.log("Error when tracking login:", x)
          );

          const dashboard = await getFavouriteDashboard().catch((x) =>
            console.log("Error when getting favourite dashboard:", x)
          );
          localStorage.setItem("favoriteDashboard", "/dashboard/dashboard_02");
          if (dashboard?.favoriteDashboard) {
            localStorage.setItem(
              "favoriteDashboard",
              "/dashboard/" + dashboard?.favoriteDashboard
            );
          }
          router.push(localStorage.getItem("favoriteDashboard"));
        }
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // If silent token acquisition fails, redirect to login
        msalInstance.acquireTokenRedirect({
          scopes: ["openid"], // Specify the required scopes here
        });
      } else {
        console.error("Error acquiring token:", error);
      }
    }
  };

  useEffect(() => {
    if (initialized && msalInstance.getAllAccounts().length > 0) {
      fetchAndSetToken(); // Fetch the token and set it as a cookie
    }
  }, [initialized]);
  /* END - MSAL SETUP */

  useEffect(() => {
    if (
      getCookie("token") != null &&
      (router.pathname === "/" || router.pathname === "/dashboard")
    ) {
      const favoriteDashboard = localStorage.getItem("favoriteDashboard");
      const redirectTo = favoriteDashboard
        ? favoriteDashboard
        : "/dashboard/dashboard_02";
      router.replace(redirectTo);
    }
  }, [router.pathname]);

  const onCloseAlert = (messageId) => {
    onClose();
    if (toast.isActive(messageId)) {
      toast.close(messageId);
    }
  };

  const getToken = async () => {
    try {
      const firebaseConfig: IFireBaseConfiguration = {
        apiKey: `${publicRuntimeConfig.FIREBASE_APIKEY}`,
        authDomain: `${publicRuntimeConfig.FIREBASE_AUTHDOMAIN}`,
        projectId: `${publicRuntimeConfig.FIREBASE_PROJECTID}`,
        storageBucket: `${publicRuntimeConfig.FIREBASE_STORAGEBUCKET}`,
        messageSenderId: `${publicRuntimeConfig.FIREBASE_MESSAGESENDERID}`,
        appId: `${publicRuntimeConfig.FIREBASE_APPID}`,
        vapidKey: `${publicRuntimeConfig.FIREBASE_VAPIDKEY}`,
      };
      const token = await firebaseCloudMessaging.init(firebaseConfig);
      if (token) {
        const payload = await firebaseCloudMessaging.getMessage();
        setFcmToken(token);
        // console.log("fcmToken", token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      const hasFirebaseMessagingSupport = await isSupported();
      if (hasFirebaseMessagingSupport) {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js", { scope: "/" })
            .then((registration) => navigator.serviceWorker.ready)
            .then((registration) => {
              // console.log("service worker registered successfully");
            })
            .catch((err) => {
              // console.log(`ServiceWorker registration failed: ${err}`);
            });

          navigator.serviceWorker.addEventListener("message", (event) => {
            //  console.log(event.data);
            const messageId = event.data.fcmMessageId;
            if (!toast.isActive(messageId)) {
              toast({
                //status: "info",
                id: messageId,
                position: "top-right",
                duration: 15000,
                isClosable: true,
                colorScheme: "#0074B7",
                render: () => (
                  <>
                    <AlertOnScreen
                      isAuthenticated={isAuthenticated}
                      title={event.data.notification.title}
                      message={event.data.notification.body}
                      eventId={event.data.data.eventId}
                      onCloseAlert={onCloseAlert}
                      messageId={messageId}
                    />
                  </>
                ),
              });
            }
          });
        }

        navigator.serviceWorker
          .getRegistrations()
          .then(async (registrations) => {
            if (registrations.length > 0) {
              setToken();
            } else {
              await navigator.serviceWorker
                .register("/firebase-messaging-sw.js", { scope: "/" })
                //  console.log("Registration successful, scope is:", registration);
                .catch(function (err) {
                  //  console.log("Service worker registration failed, error:", err);
                });
            }
          });
      }
      async function setToken() {
        await getToken();
      }
    })();
  }, [toast]);

  useEffect(() => {
    const isEnabled = localStorage.getItem("pushNotifications");
    if (Notification.permission !== "granted") {
      if (isEnabled && isEnabled === "enabled") {
        localStorage.removeItem("pushNotifications");
      }
    } else if (Notification.permission === "granted") {
      localStorage.setItem("pushNotifications", "enabled");
    }
  }, []);

  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  const chakraTheme = extendTheme({
    ...dark,
    styles: {
      global: (props) => ({
        body: {
          color: "default",
          bg: colorMode === "light" ? "#EBE6E6" : "#000000",
          "*": {
            borderColor: colorMode === "light" ? "#CBC5C5" : "gray.600",
          },
        },
      }),
    },
  });

  const validatingSession = (
    <ThemeProvider theme={dark}>
      <ChakraProvider theme={dark}>
        <ThemeWrapper>
          <ColorModeScript initialColorMode="dark" />
          <GlobalStyle />
          <Flex
            width="100vw"
            height="100vh"
            align="center"
            justify="center"
            flexDirection="column"
            backgroundColor="#000000"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
            <Text>Validating your session...</Text>
          </Flex>
        </ThemeWrapper>
      </ChakraProvider>
    </ThemeProvider>
  );

  if (!mounted) {
    return validatingSession;
  }

  return (
    <MsalProvider instance={msalInstance}>
      {isAuthenticated ? (
        <AuthenticatedTemplate>
          <Head>
            <title>Global Feed</title>
          </Head>
          <OrganisationSettingsProvider>
            <UserPermissionsProvider>
              <UserInfoProvider>
                <LanguageProvider>
                  <ThemeProvider theme={chakraTheme}>
                    <ChakraProvider theme={chakraTheme}>
                      <ThemeWrapper>
                        <ColorModeScript initialColorMode="dark" />
                        <ProSidebarProvider>
                          <DashboardProvider>
                            <GlobalStyle />
                            {loading ? (
                              <Flex
                                width="100vw"
                                height="100vh"
                                align="center"
                                justify="center"
                                flexDirection="column"
                              >
                                <Spinner
                                  thickness="4px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="blue.500"
                                  size="xl"
                                />
                                <Text>Loading...</Text>
                              </Flex>
                            ) : (
                              <Component
                                {...pageProps}
                                fcmToken={fcmToken}
                                getToken={getToken}
                              />
                            )}
                          </DashboardProvider>
                        </ProSidebarProvider>
                      </ThemeWrapper>
                    </ChakraProvider>
                  </ThemeProvider>
                </LanguageProvider>
              </UserInfoProvider>
            </UserPermissionsProvider>
          </OrganisationSettingsProvider>
        </AuthenticatedTemplate>
      ) : (
        <UnauthenticatedTemplate>{validatingSession}</UnauthenticatedTemplate>
      )}
    </MsalProvider>
  );
}

App.getInitialProps = async (context) => {
  const ctx = context.ctx;
  const req = ctx.req;
  const cookies = req?.cookies;

  const pageProps = {
    cookies: cookies,
  };

  return { pageProps };
};
