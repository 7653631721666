import Constants from "@/common/constants";

const setCookie = (name: string, value: string, expiresAt?: any) => {
  let date = new Date();
  if (expiresAt) {
    date = new Date(expiresAt);
    date.setHours(date.getHours() + 2);
  } else {
    date.setHours(date.getHours() + Constants.DEFAULT_SESSION_LENGTH);
  }

  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name: string) => {
  const cookieValue = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookieValue ? cookieValue[2] : null;
};

const deleteCookie = (name: string) => {
  const date = new Date();
  date.setTime(date.getTime() - 1);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=;${expires};path=/`;
};

const clearAllCookies = () => {
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  });
};

export { setCookie, getCookie, deleteCookie, clearAllCookies };
