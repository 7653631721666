import Head from "next/head";
import {
    Container,
    Main,
    Title,
    Description,
    CodeTag,
} from "@/components/sharedstyles";
import Cards from "@/components/cards";
import Sidedrawer from "@/components/sidedrawer/Sidedrawer";

export default function Home() {
    return (
        <Container>
            <Head>
            </Head>
            <Main>
            </Main>
        </Container>
    );
}
